import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'
//import '../assets/css/maps.css'
import MarkerMap from './MarkerMap';

const defaultProps = {
  center: {
    lat: 52.707675,
    lng: -0.410017,
  },
  zoom: 7,
}
const location = {
  address: '55 Bishopsgate, London, EC2N 3AS',
  lat: 52.734043,
  lng: -0.802705,
}

const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)
const GoogleMap = () => (
  <div style={{ height: '500px', width: '100%' }}>
    <GoogleMapReact
      // bootstrapURLKeys={{ key: 'AlzaSyBRKoTpCZsUXfcXyxHxoK-PpXMHYwfqs8o' }}
      //bootstrapURLKeys={{ key: 'AIzaSyDhWe-Qxif2PBNdyVQlGZvkzi8R4CBsIJY' }}
      bootstrapURLKeys={{ key: 'AIzaSyAotFAmeO5idIxPDK38NNKoexhQufZK93M' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      options={{ styles: [{
      "featureType":"all","elementType":"labels.text.fill",
      "stylers":[{"color":"#444444"}]},
      {"featureType":"landscape","elementType":"all",
      "stylers":[{"color":"#f2f2f2"}]},
      {"featureType":"poi","elementType":"all",
      "stylers":[{"visibility":"off"},{"lightness":5}]},
      {"featureType":"road","elementType":"all",
      "stylers":[{"saturation":"-100"},{"lightness":45}]},
      {"featureType":"road.highway","elementType":"all",
      "stylers":[{"visibility":"simplified"}]},
      {"featureType":"road.arterial","elementType":"labels.icon",
      "stylers":[{"visibility":"off"}]},
      {"featureType":"transit","elementType":"all",
      "stylers":[{"visibility":"off"}]},
      {"featureType":"water","elementType":"all","stylers":[{"color":"#c1bfbf"},{"visibility":"on"}]}] }}
    >
        <MarkerMap
            lat={53.798457}
            lng={-1.549775}
            office="Leeds Office"
            name="6 East Parade, Leeds, LS1 2AD"
            image="map-pin-mango-1.png"
            tooltip="6 East Parade, Leeds, LS1 2AD"
          />
        <MarkerMap
            lat={51.515285}
            lng={-0.082737}
            office="Headquarters and Registered Office"
            name="55 Bishopsgate, London, EC2N 3AS"
            image="map-pin-navy-1.png"
            tooltip="55 Bishopsgate, London, EC2N 3AS"
        />

    </GoogleMapReact>
  </div>
)

export default GoogleMap
