import React from "react"
import GoogleMap from '../../components/GoogleMap'

function formatTel(tel) {
  tel = tel.split(' ').join('');
  return 'tel:' + tel;
}



function ContactUsFields({ contactDepartmentsDetails,contactLocations }){
return (
  <>
  <section className="content very-light-blue-background">
     <div className="eighty-spacer"></div>
     <div className="content-block clearfix">
        <div className="centered-content-container">
           <h3 className="savings-title">Our focus is on your requirements</h3>
           <div className="sixty-spacer"></div>
        </div>
        {contactDepartmentsDetails.length
          ? contactDepartmentsDetails.map((prod,index) => (
              <div className="contact-department-holder" key={index}>
              <h2>
                 {prod.contactTitle}
                 </h2>
              <span dangerouslySetInnerHTML={{ __html: prod.contactIntro }}/>
              <div className="depart-links">
                 <a href={formatTel(prod.contactTelephone)}>
                    {prod.contactTelephone}               </a>
                 <br/>
                 <a href={`mailto:${prod.contactEmail}`}>
                    {prod.contactEmail}               </a>
              </div>
           </div>
         ))
       : null}
           </div>
  </section>
  <section className="content navy-background">
<div className="eighty-spacer"></div>
<div className="content-block clearfix">
<div className="centered-content-container">
<h3 className="savings-title">
Locations
</h3>
<div className="sixty-spacer"></div>
</div>
</div>
<div className="contact-map-holder">
  <a name="map1"><GoogleMap /></a>
</div>

{contactLocations ? (
<div className="full-width-flex-container contact-location-container clearfix">
{contactLocations.length
  ? contactLocations.map((loc,index) => (
<div className="contact-location-holder" key={index}>
<div className="contact-location-details">
<span dangerouslySetInnerHTML={{ __html: loc.contactLocationTitle }}/><br/>
<span dangerouslySetInnerHTML={{ __html: loc.contactLocationAddress }}/><br/>
<a href={loc.contactLocationsGetDirectionsLink} target="_blank" rel="noopener noreferrer">
Get Directions
</a>
</div>
</div>
))
: null}
</div>
)
: null}
</section>
</>
 )
  }


export default ContactUsFields
